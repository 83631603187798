import React from 'react'

export default function ContactUs() {
  return (
    <div className='contactus mt-4'>
        <div>

        
        <h1 className='text-primary'>CONTACT US</h1>
        <h3>Thank you for considering us for your family's educational needs.</h3>
        
        <h4>Email</h4>
        <p>Care@theskillguru.org</p>
        <h4>Mobile number</h4>
        <p>+91-9560695530</p>
        
        </div>

    </div>
  )
}
