import React, { useEffect } from 'react';
import Teaching1 from '../assets/teaching1.jpg';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import { useMediaQuery } from 'react-responsive';

export default function Main() {
  const isMobile = useMediaQuery({maxWidth:"768px"});
  useEffect(() => {
    const img = new Image();
    img.src = Teaching1; // Preload the image
  }, []);

  return (
    <>
        <main className='main'>
        <div>
            <h2>MASTER NEW SKILLS WITH EASE</h2>
            <p>Empowering learners for a brighter future</p>
            <button className='btn btn-primary'>Start learning today</button>
            <div>
            <img src={Teaching1}  alt="Teaching1" style={{width:!isMobile?'95vw':'95vw',height:!isMobile?'80vh':"", objectFit: 'cover',marginTop:"30px"}}/>
            </div>
            
        </div>
        
    </main>
    <AboutUs/>
    <ContactUs/>
    </>
    
  )
}
