import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/logo.jpg'

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false); // State to manage menu visibility

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle menu visibility
  };

  return (
    <nav className="navbar navbar-expand-lg ">
  <div className="container-fluid">
    <a className="navbar-brand" href="/" style={{fontSize:"xx-large",fontWeight:"bold"}} ><img style={{height:"50px" ,margin:"0px 20px"}} src={Logo}/>The Skill Guru</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse " style={{marginRight:"40px"}} id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/" style={{ color: 'blue' }}>Home</Link>  
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/privacypolicy" style={{ color: 'blue' }}>Privacy Policy</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contactus" style={{ color: 'blue' }}>Contact Us</Link>
        </li>
        
        
      </ul>
      
    </div>
  </div>
</nav>
  )
}
