import React from 'react';
import { useMediaQuery } from 'react-responsive';

export default function PrivacyPolicy() {
    const isMobile = useMediaQuery({maxWidth:"768px"});
    
   
  return (
    <div className='privacy' style={{padding:"90px 30px"}}>
        <h1 className='text-primary'>PRIVACY POLICY</h1>
        <div>
          <div>
          <h2>Introduction</h2>
            <p style={{width:isMobile?"370px":'800px'}}>The Skill Guru Foundation ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy outlines the types of information we collect, how we use it, and your rights regarding your data when you use The Skill Guru App.
            </p>
          </div>
          <div className='mt-4'>
            <h2>Information we collect</h2>
            <h5>We may collect :</h5>
            <p>Name, email address, phone number, age, and any other details provided during sign-up.</p>
            <h5>Usage Data :</h5>
            <p>App interactions, preferences, and usage patterns.</p>
            <h5>Device Information :</h5>
            <p>Device model, operating system, and unique identifiers.How We Use Your Information</p>
            <h5>Your data is used to :</h5>
            <ul>
              <li>Facilitate communication between Gurus and Learners.</li>
              <li>Improve app functionality and provide a personalized experience.</li>
              <li>Ensure security and prevent misuse.</li>
              <li>Send notifications, updates, and promotional materials.</li>
            </ul>
          </div>
          <div>
            <h2>Sharing Your Information</h2>
            <h5>We do not sell or rent your information to third parties. We may share data with:</h5>
            <ul>
              <li>Trusted service providers aiding app operations.</li>
              <li>Legal authorities, if required by law.</li>
            </ul>
          </div>
          <div>
            <h2>Your Rights</h2>
            <h5>You have the right to :</h5>
            <ul>
              <li>Access, update, or delete your personal data.</li>
              <li>Opt-out of marketing communications.</li>
              <li>File a complaint about data usage.</li>
            </ul>
          </div>
          <div>
            <h2>Contact Us</h2>
            <h4>If you have any concerns about this Privacy Policy, contact us at:</h4>
            <div className='d-flex'>
              <h5 className='mx-2'>Email : </h5>

              <p> care@theskillguru.org</p>
            </div>
            <div className='d-flex'>
              <h5 className='mx-2'>Phone : </h5>

              <p>  +91-9560695530
              </p>
            </div>
          </div>
            
        </div>

    </div>
  )
}
