import React, { useEffect } from 'react'
import Teaching2 from '../assets/teaching2.jpg';
import { useMediaQuery } from 'react-responsive';


export default function AboutUs() {
    const isMobile = useMediaQuery({maxWidth:"768px"});
    useEffect(() => {
        const img = new Image();
        img.src = Teaching2; // Preload the image
    }, []);

    return (
        <div className='aboutus container'>
            <div className='row' >
                <div className='col-12 text-center p-5'>
                    <h1 className='title text-primary'>ABOUT THE SKILL GURU</h1>
                </div>
                <div className='col-12 col-md-6'>
                    <img className='img-fluid'  src={Teaching2} alt="Teaching" style={{ height: "90%" }} />
                </div>
                <div className='col-sm-12 col-md-6 col-lg-6' style={{}}>
                    <div className='details' style={{marginTop:isMobile?'0px':'130px'}}>
                        <h2>Our Mission</h2>
                        <p>At Skill Guru, we strive to provide the best possible education to our students by creating an environment that fosters learning, growth and personal development</p>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
