import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './screens/Navbar';
import Main from './screens/Main';
import PrivacyPolicy from './screens/privacyPolicy'; // Create this component
import './App.css'
import ContactUs1 from './screens/ContactUs1';

function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/contactus" element={<ContactUs1/>} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            </Routes>
        </Router>
    );
}

export default App; 